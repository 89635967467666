import { types as t, Instance } from 'mobx-state-tree';
import BaseConfiguration from './base';

const ReportConfigurationModel = t.compose(
  t.model({
    node_ids: t.array(t.string),
    subscribers: t.array(t.string),
    parameters: t.array(t.frozen()),
    view: t.maybeNull(t.optional(t.string, 'basic')),
    standard: t.maybeNull(t.optional(t.string, 'aethair_blue'))
  }),
  BaseConfiguration
);

export interface IReportConfigurationModel extends Instance<typeof ReportConfigurationModel> {}

export default ReportConfigurationModel;
