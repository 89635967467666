import { types as t, Instance } from 'mobx-state-tree';
import BaseConfiguration from './base';

const ReportConfigurationModel = t.compose(
  t.model({
    node_ids: t.array(t.string),
    subscribers: t.array(t.string),
    parameters: t.array(t.frozen()),
    wind_speed: t.array(t.string),
    wind_direction: t.array(t.string)
  }),
  BaseConfiguration
);

export interface IReportConfigurationModel extends Instance<typeof ReportConfigurationModel> {}

export default ReportConfigurationModel;
