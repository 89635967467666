import React, { useCallback, useState } from 'react';
import { Menu, MenuItem, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ButtonDesktopOnly from '~/components/adaptive_button';
import useLocales from '~/hooks/use_locales';
import CopyToClipboard from '~/components/copy_to_clipboard';

function ReportContent({ reportUrl }: { reportUrl: string }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useLocales();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const open = Boolean(anchorEl);

  return (
    <Box>
      <ButtonDesktopOnly
        aria-haspopup="true"
        variant="contained"
        size="medium"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        fullWidth
        breakpoint="sm"
      >
        {t('devices.actions.title')}
      </ButtonDesktopOnly>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1,
            '& .MuiSvgIcon-root': {
              marginRight: 2
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => window.open(`${reportUrl}&print_pdf=true`)}>{t('base.buttons.download_pdf')}</MenuItem>
        <CopyToClipboard value={reportUrl} data-dd-action-name="Copy URL">
          <MenuItem>{t('base.buttons.copy_url')}</MenuItem>
        </CopyToClipboard>
      </Menu>
    </Box>
  );
}

export default React.memo(ReportContent);
