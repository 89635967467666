import { Chip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { OnChange } from 'react-final-form-listeners';

import Autocomplete from '~/components/final_form/select';
import { ListItemStyled, ExternalIdStyled } from '~/components/devices_select/styled';
import { GroupHeader } from '~/components/widgets/chart_widget/configuration/styled';
import useMst from '~/hooks/use_mst';
import { useForm } from 'react-final-form';

type DataPointsSelectProps = {
  options: any;
  loading: boolean;
  label: string;
  name: string;
  multiple?: boolean;
  nodes: any;
};

function DataPointsSelect({ options, loading, label, name, multiple }: DataPointsSelectProps) {
  const { nodes } = useMst();
  const { change } = useForm();

  return (
    <>
      <Autocomplete
        searchable
        name={name}
        label={label}
        options={options}
        multiple={multiple}
        loading={loading}
        groupBy={({ model }) => model?.node_id}
        disableCloseOnSelect
        handleHomeEndKeys={false}
        renderGroup={(groups) => {
          const node = nodes.getById(groups.group);
          return (
            <li key={groups.key}>
              <GroupHeader>
                <ListItemStyled>
                  {node?.presentName}
                  <ExternalIdStyled className="ExternalId">{node?.serial}</ExternalIdStyled>
                </ListItemStyled>
              </GroupHeader>
              <ul>{groups.children}</ul>
            </li>
          );
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue
            .filter((option) => Boolean(option))
            .map((option, index) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Chip {...getTagProps({ index })} label={`${option?.label} (${nodes.getById(option?.model?.node_id)?.presentName})`} />
            ))
        }
      />
      <OnChange name="configuration.node_ids">{() => change(name, [])}</OnChange>
    </>
  );
}

export default observer(DataPointsSelect);
