import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { computed } from 'mobx';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import groupBy from 'lodash/fp/groupBy';
import compact from 'lodash/fp/compact';
import sortBy from 'lodash/fp/sortBy';

import Select from '~/components/final_form/select';
import useLocales from '~/hooks/use_locales';

import useDataPoints from './hooks/use_data_points';

function AQParameters() {
  const { t } = useLocales();
  const { dataPoints, parameterOptions } = useDataPoints();

  const options = useMemo(
    () =>
      computed(() => {
        return flow(
          compact,
          groupBy(({ label }) => label),
          map.convert({ cap: false })((values, name) => ({ models: values.map((value) => value.model), label: name, value: name })),
          sortBy('label')
        )(parameterOptions);
      }),
    [parameterOptions]
  ).get();

  return (
    <Select
      searchable
      multiple
      name="configuration.parameters"
      label={t('thiamis.download_csv.parameters')}
      options={options}
      loading={dataPoints.isFetching}
    />
  );
}

export default observer(AQParameters);
