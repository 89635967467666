import RadioGroup from '~/components/final_form/radio_input';
import DevicesSelect from '~/components/devices_select';
import FormGrid from '~/components/@material-extend/form_grid';
import Select from '~/components/final_form/select';
import useLocales from '~/hooks/use_locales';

import { observer } from 'mobx-react-lite';
import { Card, CardHeader } from '@mui/material';
import { CardContentStyled } from '~/pages/report_edit/styled';
import Parameters from '../parameters';
import useNodes from '../parameters/hooks/use_nodes';

const standardOptions = [{ value: 'aethair_blue', label: 'Aethair Blue' }];
const viewOptions = [
  { value: 'basic', label: 'Basic' },
  { value: 'extended', label: 'Extended' }
];

function AQIReportContent() {
  const { t } = useLocales();
  const { nodes, nodesOptions } = useNodes();

  return (
    <Card>
      <CardHeader title="Settings" />
      <CardContentStyled>
        <FormGrid columns="1">
          <FormGrid columns="2">
            <Select options={standardOptions} label={t('attributes.report.standard')} name="configuration.standard" />
          </FormGrid>
          <DevicesSelect
            allowSelectAll
            name="configuration.node_ids"
            loading={nodes.isFetching}
            label={t('attributes.report.node_ids')}
            options={nodesOptions || []}
            multiple
          />
          <Parameters />
          <RadioGroup name="configuration.view" label="View" options={viewOptions} />
        </FormGrid>
      </CardContentStyled>
    </Card>
  );
}

export default observer(AQIReportContent);
