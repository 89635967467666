import { useEffect, useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { observable, action, computed, autorun } from 'mobx';
import DataPoints from '~/mst/models/data_points/node';
import { getOptionsForPayload } from '~/components/final_form/select/utils';
import useFetch from '~/hooks/use_fetch';
import { filterByUserSettings } from '~/mst/models/data_points/node/views';
import useMst from '~/hooks/use_mst';

const params = observable(
  {
    node_id: null,
    setNodeId(value) {
      this.node_id = value;
    }
  },
  {
    setNodeId: action
  }
);

export default function useDataPoints() {
  const { auth, nodes } = useMst();
  const {
    values: {
      configuration: { node_ids: nodeIds }
    }
  } = useFormState({ subscription: { values: true } });

  useEffect(() => autorun(() => params.setNodeId(getOptionsForPayload(nodeIds).filter((id) => Boolean(nodes.getById(id))))), [nodes, nodeIds]);

  const dataPoints = useMemo(() => DataPoints.create({}), []);
  useFetch(dataPoints, params);

  const tempUnits = auth?.user?.temp_units;
  const parameterOptions = useMemo(
    () =>
      computed(() =>
        filterByUserSettings(
          dataPoints.defaultValueLabelPairs.sort(
            // eslint-disable-next-line no-unsafe-optional-chaining
            (a, b) => -nodes.getById(b.model.node_id)?.presentName.localeCompare(nodes.getById(a.model.node_id)?.presentName)
          ),
          tempUnits
        )
      ),
    [dataPoints, tempUnits, nodes]
  ).get();

  return {
    dataPoints,
    parameterOptions
  };
}
