import React from 'react';
import { Box } from '@mui/material';
import { useFormState } from 'react-final-form';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';
import isString from 'lodash/isString';
import useLocales from '~/hooks/use_locales';
import FormGrid from '~/components/@material-extend/form_grid';
import DatePicker from '~/components/final_form/date_picker';
import TimePicker from '~/components/final_form/time_picker';
import Switch from '~/components/final_form/switch';

function DateTimeSection() {
  const { t } = useLocales();
  const {
    values: {
      configuration: { from, working_hours: workingHours, time_zone: timeZone }
    }
  } = useFormState({ subscription: { values: true } });

  return (
    <FormGrid columns="2" rowGap={1}>
      <DatePicker name="configuration.from" label={t('thiamis.download_csv.from')} timezone={timeZone} disableFuture />
      <DatePicker
        name="configuration.to"
        label={t('thiamis.download_csv.to')}
        timezone={timeZone}
        minDate={isString(from) && DateTime.fromISO(from)?.isValid ? DateTime.fromISO(from) : null}
        disableFuture
      />
      <Box>
        <Switch label={t('attributes.report.working_hours')} name="configuration.working_hours.enabled" />
      </Box>
      {workingHours?.enabled && (
        <FormGrid columns="2">
          <TimePicker name="configuration.working_hours.from" label={t('thiamis.download_csv.from')} />
          <TimePicker name="configuration.working_hours.to" label={t('thiamis.download_csv.to')} />
        </FormGrid>
      )}
    </FormGrid>
  );
}

export default observer(DateTimeSection);
