/* eslint-disable import/prefer-default-export */
import request from '../request';

const { VITE_ANALYTICS_URL_ROOT } = process.env;

export function read(id: string, params) {
  return request('get', `nodes/${id}`, params);
}

export function fetchAvailableDevices(payload: any) {
  return [request, 'put', 'nodes/available_devices', payload];
}

export function update(id, payload: any) {
  return request('put', `nodes/${id}`, payload);
}

export function create(payload: any) {
  return request('post', 'nodes', payload);
}

export function destroy(id: string) {
  return request('delete', `nodes/${id}`);
}

export function activate(id) {
  return request('put', `nodes/${id}/activate`);
}

export function deactivate(id) {
  return request('put', `nodes/${id}/deactivate`);
}

export function share(nodeId, organizationId) {
  return request('put', `nodes/${nodeId}/shares/${organizationId}`);
}

export function removeShare(nodeId, organizationId) {
  return request('delete', `nodes/${nodeId}/shares/${organizationId}`);
}

export function lease(nodeId, organizationId) {
  return request('put', `nodes/${nodeId}/lease/${organizationId}`);
}

export function removeLease(nodeId) {
  return request('delete', `nodes/${nodeId}/lease`);
}

export function claim(payload) {
  return request('post', 'nodes/claim', payload);
}

export function transfer(nodeId, organizationId, billingId) {
  return request('put', `nodes/${nodeId}/transfer/${organizationId}`, { payment: billingId });
}

export function createMetric(nodeId, payload) {
  return request('post', `nodes/${nodeId}/metrics`, payload);
}

export function destroyMetric(nodeId, dataPointId) {
  return request('delete', `nodes/${nodeId}/metrics/${dataPointId}`);
}

export function runAction(nodeId, action, payload?) {
  return request('put', `nodes/${nodeId}/actions/${action}`, payload);
}

export function changeEndpoint(serial, payload) {
  return request('put', `thiamis/${serial}/endpoint`, payload);
}

export function getDefaultWifi(nodeId) {
  return [request, 'get', `nodes/${nodeId}/default_wifi`];
}

export function downloadCalibrationCertificate(nodeId: string, data: any) {
  return request('post', `${VITE_ANALYTICS_URL_ROOT}certificates/${nodeId}?print_pdf=true`, { data }, { responseType: 'blob', api: false });
}

export function downloadDeviceCertificate(serial: string) {
  return request('post', `thiamis/${serial}.crt`, null, { responseType: 'blob' });
}
