import React, { useMemo } from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { OnChange } from 'react-final-form-listeners';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import flow from 'lodash/fp/flow';
import first from 'lodash/first';
import map from 'lodash/fp/map';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import { useForm, useFormState } from 'react-final-form';

import TextInput from '~/components/final_form/text_input';
import HiddenInput from '~/components/final_form/hidden_input';
import FormGrid from '~/components/@material-extend/form_grid';
import OrganizationSelect from '~/components/organizations_select';
import Select from '~/components/final_form/select';
import useLocales from '~/hooks/use_locales';
import useMst from '~/hooks/use_mst';
import { IReport } from '~/mst/models/report';
import { PATHS } from '~/utils/constants';
import { getOptionsForPayload } from '~/components/final_form/select/utils';
import { ReportTypes } from '~/mst/models/report/types';
import { CardContentStyled } from '~/pages/report_edit/styled';
import ReportTypeContent from './report_type_content';
import DateTimeSection from './date_time_section';

interface BaseFormProps {
  model: IReport;
}

function BaseForm({ model }: BaseFormProps) {
  const { t } = useLocales();
  const { organizations, auth, nodes } = useMst();
  const { change } = useForm();
  const {
    values: { configuration }
  } = useFormState({ subscription: { values: true } });

  const typeOptions = useMemo(
    () =>
      Object.values(ReportTypes).map((value) => ({
        value,
        label: t(`attributes.report.types.${value}`),
        ...(auth.user.isSupport === false && {
          disabled: value === ReportTypes.perimeter_monitor
        })
      })),
    [auth.user?.isSupport, t]
  );

  const aqStandardParameters = useMemo(() => {
    const tempUnits = auth.user?.temp_units || '°F';
    return [
      { value: 'AQ', label: 'AQ' },
      { value: 'CO₂, ppm', label: 'CO₂, ppm' },
      { value: 'CH₂O, mg/m³', label: 'CH₂O, mg/m³' },
      { value: 'Humidity, %', label: 'Humidity, %' },
      { value: 'PM, µg/m³', label: 'PM, µg/m³' },
      { value: `Temperature, ${tempUnits}`, label: `Temperature, ${tempUnits}` },
      { value: 'VOC (Isobutylene), ppm', label: 'VOC (Isobutylene), ppm' }
    ];
  }, [auth.user?.temp_units]);

  return (
    <Card>
      <CardContent>
        <FormGrid rowGap={3}>
          <HiddenInput name="configuration.time_zone" />
          <FormGrid columns="2">
            <Select options={typeOptions} label={t('attributes.report.type')} name="configuration.type" />
            <OrganizationSelect
              data-testid="reportOwnerSelect"
              disabled={!model.isNew}
              options={organizations.valueLabelPairsManagerAccess}
              name="organization_id"
              label={t('attributes.alert.organization_id')}
            />
            <TextInput name="name" label={t('attributes.report.name')} />
            <TextInput name="description" label={t('attributes.report.description')} />
          </FormGrid>
          <ReportTypeContent model={model} />
          <Card>
            <CardHeader title="Time Period" />
            <CardContentStyled>
              <DateTimeSection />
            </CardContentStyled>
          </Card>
        </FormGrid>
      </CardContent>
      <OnChange name="configuration.type">
        {debounce((value: string) => {
          if (value === ReportTypes.perimeter_monitor) {
            change('configuration', {
              ...configuration,
              node_ids: [],
              wind_speed: [],
              wind_direction: [],
              parameters: [
                {
                  name: 'Particles',
                  unit: 'µg/m³',
                  alert_level: '100',
                  data_point_ids: []
                },
                {
                  name: 'Gases',
                  unit: 'ppm',
                  alert_level: '5',
                  data_point_ids: []
                }
              ]
            });
          } else if (value === ReportTypes.air_quality) {
            change('configuration', {
              ...configuration,
              standard: 'aethair_blue',
              view: 'basic',
              node_ids: [],
              parameters: aqStandardParameters
            });
          }
        }, 300)}
      </OnChange>
      <OnChange name="configuration.node_ids">
        {async (value) => {
          const nodesIds = getOptionsForPayload(value);
          if (!isEmpty(nodesIds)) {
            await nodes.fetchDataPoints({ last: 1, path: [PATHS.TIMEZONE], node_id: nodesIds });
            const timezone = flow(
              map((id) => nodes.getById(id)?.data_points?.getByPath(PATHS.TIMEZONE)?.lastValue),
              compact,
              uniq,
              first
            )(nodesIds);
            if (timezone) {
              change('configuration.time_zone', timezone);
            }
          }
        }}
      </OnChange>
    </Card>
  );
}

export default observer(BaseForm);
