import { useEffect } from 'react';
import { useFormState } from 'react-final-form';
import { observable, action } from 'mobx';
import useFetch from '~/hooks/use_fetch';
import useMst from '~/hooks/use_mst';

const paramsOragnizationId = observable(
  {
    organization_id: null,
    setOrganizationId(value) {
      this.organization_id = value;
    }
  },
  {
    setOrganizationId: action
  }
);

export default function useNodes() {
  const { nodes } = useMst();
  const {
    values: { organization_id: organizationId }
  } = useFormState({ subscription: { values: true } });

  useEffect(() => paramsOragnizationId.setOrganizationId(organizationId), [organizationId]);
  useFetch(nodes, paramsOragnizationId);

  return {
    nodes,
    nodesOptions: nodes.monitorableValueLabelPairs || []
  };
}
