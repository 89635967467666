import React, { useState } from 'react';
import { Card, CardContent } from '@mui/material';
import { IFrameRenderer } from '~/components/iframe_renderer';

type ReportContentProps = {
  name: string;
  reportUrl: string;
};

function ReportContent({ name, reportUrl }: ReportContentProps) {
  const [isIFrameLoaded, setIsIFrameLoaded] = useState<boolean>(false);

  return (
    <Card>
      <CardContent>
        {isIFrameLoaded === false && <div>Loading...</div>}
        <IFrameRenderer
          sourceUrl={reportUrl}
          title={name}
          handleOnLoad={() => {
            setIsIFrameLoaded(true);
          }}
        />
      </CardContent>
    </Card>
  );
}

export default React.memo(ReportContent);
