/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useMemo } from 'react';
import { getSnapshot } from 'mobx-state-tree';
import { Form as FinalForm } from 'react-final-form';
import { observer } from 'mobx-react-lite';
import { computed } from 'mobx';
import arrayMutators from 'final-form-arrays';
import useNavigate from '~/hooks/use_navigate';
import useMst from '~/hooks/use_mst';
import useLocales from '~/hooks/use_locales';
import { useValidationSchema } from '~/hooks/use_validate';
import toasts from '~/utils/toasts';
import { IReport } from '~/mst/models/report';
import Yup, { parseDate } from '~/utils/yup';
import { getOptionsForPayload } from '~/components/final_form/select/utils';
import { captureException } from '~/services/sentry';
import { ReportTypes } from '~/mst/models/report/types';
import Form from './form';

const ReportSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  organization_id: Yup.string().nullable().required('Organization is required'),
  configuration: Yup.object()
    .shape({
      from: Yup.date().transform(parseDate).required('Required'),
      to: Yup.date().transform(parseDate).required('Required'),
      node_ids: Yup.array().required('Required').min(1, 'Device is required'),
      working_hours: Yup.object()
        .nullable()
        .shape({
          from: Yup.date()
            .transform(parseDate)
            .when('enabled', {
              is: true,
              then: () => Yup.date().transform(parseDate).required('Required'),
              otherwise: () => Yup.date().transform(parseDate).notRequired()
            }),
          to: Yup.date()
            .transform(parseDate)
            .when('enabled', {
              is: true,
              then: () => Yup.date().transform(parseDate).required('Required'),
              otherwise: () => Yup.date().transform(parseDate).notRequired()
            })
        }),
      type: Yup.string().required('Required'),
      standard: Yup.string().when('type', {
        is: 'air_quality',
        then: () => Yup.string().required('Required'),
        otherwise: () => Yup.string().notRequired()
      }),
      parameters: Yup.array().min(1, 'At least one parameter is required'),
      wind_speed: Yup.array().when('type', {
        is: 'perimeter_monitor',
        then: () => Yup.array().length(1, 'Exactly one wind speed parameter must be selected'),
        otherwise: () => Yup.array().notRequired()
      }),
      wind_direction: Yup.array().when('type', {
        is: 'perimeter_monitor',
        then: () => Yup.array().length(1, 'Exactly one wind direction parameter must be selected'),
        otherwise: () => Yup.array().notRequired()
      })
    })
    .required('This field is required.')
});

function ReportForm({ model }: { model: IReport }) {
  const { navigate } = useNavigate();
  const { t } = useLocales();

  const { reports } = useMst();
  const { auth } = useMst();

  const validate = useValidationSchema(ReportSchema);

  const handleFormSubmit = useCallback(
    async (values) => {
      const {
        configuration: { node_ids: nodeIds, subscribers, working_hours, parameters, type }
      } = values;
      const payload = {
        ...values,
        configuration: {
          ...values.configuration,
          node_ids: getOptionsForPayload(nodeIds),
          subscribers: getOptionsForPayload(subscribers),
          ...(values.configuration.wind_speed && { wind_speed: getOptionsForPayload(values.configuration.wind_speed) }),
          ...(values.configuration.wind_direction && { wind_direction: getOptionsForPayload(values.configuration.wind_direction) }),
          parameters:
            type === ReportTypes.perimeter_monitor
              ? parameters.map((parameter) => ({ ...parameter, data_point_ids: getOptionsForPayload(parameter.data_point_ids) }))
              : parameters.map((parameter) => (parameter.value && parameter.label ? parameter.value : parameter)),
          working_hours: working_hours?.enabled ? working_hours : null
        }
      };
      try {
        if (model.isNew) {
          await reports.create(payload);
        } else {
          await model.update(payload);
        }
        navigate(`reports`);
        toasts.success(t(`notifications.success.forms.${model.isNew ? 'create_report' : 'update_report'}`));
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [model, t, navigate, reports]
  );

  const initialValues = useMemo(
    () =>
      computed(() => ({
        ...getSnapshot(model),
        ...(model.isNew && {
          organization_id: auth.organizationId,
          configuration: {}
        })
      })),
    [model, auth.organizationId]
  ).get();
  return (
    <FinalForm
      validate={validate}
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      onSubmit={handleFormSubmit}
      subscription={{ submitting: true, pristine: true, dirty: true }}
      render={({ handleSubmit, invalid, submitting }) => <Form handleSubmit={handleSubmit} model={model} invalid={invalid} submitting={submitting} />}
    />
  );
}

export default observer(ReportForm);
