import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Card, CardHeader } from '@mui/material';
import useLocales from '~/hooks/use_locales';
import FormGrid from '~/components/@material-extend/form_grid';
import DevicesSelect from '~/components/devices_select';
import { CardContentStyled } from '~/pages/report_edit/styled';
import PerimeterParameters from '../parameters/perimeter_parameters';
import useDataPoints from '../parameters/hooks/use_data_points';
import useNodes from '../parameters/hooks/use_nodes';
import DataPointsSelect from '../parameters/data_points_select';

const WIND_SPEED_REGEXP = /^(?=.*wind)(?=.*speed).*$/i;
const WIND_DIRECTION_REGEXP = /^(?=.*wind)(?=.*direction).*$/i;

function PerimeterReportContent() {
  const { t } = useLocales();
  const { nodesOptions, nodes } = useNodes();
  const { parameterOptions, dataPoints } = useDataPoints();
  const windSpeedOptions = useMemo(() => parameterOptions.filter(({ label }) => WIND_SPEED_REGEXP.test(label)), [parameterOptions]);
  const windDirectionOptions = useMemo(() => parameterOptions.filter(({ label }) => WIND_DIRECTION_REGEXP.test(label)), [parameterOptions]);

  return (
    <FormGrid rowGap={3}>
      <Card>
        <CardHeader title="Settings" />
        <CardContentStyled>
          <FormGrid rowGap={3}>
            <DevicesSelect
              name="configuration.node_ids"
              label={t('attributes.report.node_ids')}
              loading={nodes.isFetching}
              options={nodesOptions}
              multiple
            />
            <FormGrid rowGap={3} columns={2}>
              <DataPointsSelect
                multiple
                name="configuration.wind_speed"
                label="Wind Speed"
                options={windSpeedOptions || []}
                loading={dataPoints.isFetching}
              />
              <DataPointsSelect
                multiple
                name="configuration.wind_direction"
                label="Wind Direction"
                options={windDirectionOptions || []}
                loading={dataPoints.isFetching}
              />
            </FormGrid>
            <PerimeterParameters />
          </FormGrid>
        </CardContentStyled>
      </Card>
    </FormGrid>
  );
}

export default observer(PerimeterReportContent);
