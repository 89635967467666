import React from 'react';
import { observer } from 'mobx-react-lite';
import { useFormState } from 'react-final-form';
import { ReportTypes } from '~/mst/models/report/types';
import AQParameters from './aq_parameters';
import PerimeterParameters from './perimeter_parameters';

function Parameters() {
  const {
    values: {
      configuration: { type }
    }
  } = useFormState({ subscription: { values: true } });

  if ([ReportTypes.perimeter_monitor].includes(type)) {
    return <PerimeterParameters />;
  }

  return <AQParameters />;
}

export default observer(Parameters);
