import { types as t, Instance } from 'mobx-state-tree';
import { ReportTypes } from '../types';

const ReportConfigurationModel = t.model({
  subscribers: t.array(t.string),
  type: t.optional(t.string, ReportTypes.air_quality),
  from: t.maybeNull(t.union(t.string, t.number)),
  to: t.maybeNull(t.union(t.string, t.number)),
  time_zone: t.maybeNull(t.string),
  working_hours: t.maybeNull(t.frozen())
});

export interface IReportConfigurationModel extends Instance<typeof ReportConfigurationModel> {}

export default ReportConfigurationModel;
